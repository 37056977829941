const en = {
  'login.accept': 'Accept',
  'login.and': 'and',
  'login.backTo': 'Back to',
  'login.backToLogin': 'Back to Login',
  'login.caseLetters': 'Must include both uppercase and lowercase letters',
  'login.checkboxcookie':
    'This website stores cookies on your computer. These cookies are used to collect information about how you interact with our system and allow us to remember you. We use this information in order to improve and customize your user experience and for analytics and metrics about our users. To find out more about the cookies we use, see our',
  'login.clientError': 'Client error',
  'login.confirmPassword': 'Confirm New Password',
  'login.decline': 'Decline',
  'login.declineButtonText': 'Decline Terms and Log Out',
  'login.declineNYSHEXTerms':
    'By declining our terms, you will lose access to NYSHEX!',
  'login.declinePrivacyPolicy':
    'If you decline the updated terms of our privacy policy you will not be permitted to access our system and will be sent back to the log in page',
  'login.devDisclaimer':
    'All data, including offers and contracts are dummy data for development purposes only. Neither NYSHEX or any party using this environment is responsible for the accuracy of data displayed in this environment.For additional information, please contact',
  'login.email': 'Email',
  'login.emailConfirmation.line1':
    'If an account exists, an email with instructions will be sent to the email address associated to your account.',
  'login.emailConfirmation.line2':
    "If you don't see the email, check other places it might be, like your junk, spam, social, or other folders.",
  'login.emailRequired': 'Email is required',
  'login.emailText':
    'Enter your email address below and we’ll get you back on track.',
  'login.forgetMe': ' Forget Me',
  'login.forgetPasswordUsernameLabel':
    "Enter your username or email below and we'll get you back on track.",
  'login.forgot': 'Forgot?',
  'login.forgotPassword': 'Forgot Your Password?',
  'login.gdprText':
    'We care about your privacy and that’s why we were already GDPR compliant, and why we only store customer data that is needed to provide our services. We also think privacy is for everyone, which is why we’re ensuring that our policies continue to meet the high standard of the European data protection laws, and why we’re extending the same rights to all our users, inside and outside the EU.',
  'login.improperEmailFormat': 'Improper Email Format',
  'login.liveDisclaimer':
    'All contracts entered into the NYSHEX contracting platform are legally binding, and all parties publishing data on the system warrant its accuracy. By logging into this site you expressly accept and agree to be bound by the NYSHEX Standard Operating Terms and Conditions in addition to your Membership Agreement. NYSHEX retains the right to modify its Standard Operating Terms and Conditions from time to time at its sole discretion, and such modification will be effective immediately upon posting. You are responsible for adhering to these Terms and Conditions at all times. For additional information, please contact ',
  'login.loggingIn': 'Logging in',
  'login.logIn': 'Log In',
  'login.maxLength20': 'Max Length can be 20 characters',
  'login.maxLength255': 'Max Length can be 255 characters',
  'login.length1230': '12-30 characters',
  'login.needHelp': 'Need Help?',
  'login.networkError': 'Network Error',
  'login.new': 'The new',
  'login.newPassword': 'New Password',
  'login.newPasswordText':
    'You may now log in to the site using your new password.',
  'login.noAccountText': "Don't have an account yet?",
  'login.password': 'Password',
  'login.password1Number': 'Contains a number (0–9)',
  'login.passwordResetLinkExpired': 'Password Reset Link Has Expired',
  'login.passwordResetLinkInvalid': 'Password Reset Link Is Invalid',
  'login.passwordResetLinkUsed': 'Password Reset Link Has Been Used',
  'login.passwordMatch': 'Passwords match',
  'login.passwordRequired': 'Password is required',
  'login.passwordResetRequested': 'Password Reset Requested',
  'login.passwordsDontMatch': "Passwords don't match",
  'login.passwordSetUpText':
    'Please set a password for your account to complete set up and log in.',
  'login.passwordSpecial':
    'Contains one or more of the following special characters: !#$%^&*',
  'login.passwordUpdated': 'Your Password was Updated',
  'login.privacyPolicy': 'Privacy Policy',
  'login.requestingLink': 'Requesting Link',
  'login.requestResetLink': 'Request Reset Link',
  'login.resetPassword': 'Reset Your Password',
  'login.serverError': 'Server error: ',
  'login.signIn': 'Sign In',
  'login.signUp': 'Sign up',
  'login.takeMeBack': 'No, Take Me Back',
  'login.termsOfService': 'Terms of Service',
  'login.tos.header': 'We’re updating our Terms of Service and Privacy Policy',
  'login.tos.time':
    'will take effect on June 1, 2018 and, by using NYSHEX or any of our services on or after that date, you will be agreeing to these updates.',
  'login.tosText.label':
    'We’re making some important updates to our Terms of Service and Privacy Policy. Don’t worry, these are just small changes to make it easier for you to find out what data we store and what you can do with it.',
  'login.unexpectedError': 'Unexpected error',
  'login.updatePassword': 'Update Password',
  'login.updatingPassword': 'Updating Password',
  'login.username': 'Username or email',
  'login.usernameRequired': 'Username or email is required',
  'login.welcome': 'Welcome',
  'Reset link has expired or is invalid':
    'Reset link has expired or is invalid',
};

const t = (key) => en[key];

export default t;
