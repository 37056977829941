import { useEffect, useState } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import qs from 'qs';
import SignInForm from 'components/SignInForm';
import ForgotPasswordForm from 'components/ForgotPasswordForm';
import ResetPasswordForm from 'components/ResetPasswordForm';
import Footer from 'components/shared/Footer';
import EmailConfirmation from 'components/EmailConfirmation';
import ResetConfirmation from 'components/ResetConfirmation';
import NewUser from 'components/NewUser';
import AlertDialog from 'components/modals/AlertDialog';
import DeclineDialog from 'components/modals/DeclineDialog';
import { publishEvent } from 'analyticsService.js';
import config from 'config';
import t from 'js_utils/translations';
import {
  loginUser,
  missingConsent,
  giveConsent,
  refreshLogin,
} from 'api/login.api';
import { fetchApplicationVersion } from 'applicationVersionService';
import { updateRollbarConfig, logErrorInfo } from 'RollbarErrorTracking';
import { getStyle } from 'js_utils/styles';
import { WHITE_LABEL_NAMES } from 'js_utils/functions';

import globalStyles from 'assets/css/global_styles.css';

const TOC = 'ToC-2018-05-25';
const CONSENT_COOKIE = 'Cookies-2018-05-25';
const REDIRECT = 'redirect_to';

const transformPath = (path) => path.slice(1);
const getRedirectLink = (isAdmin = false) => {
  const href = window.location.href;
  if (href.indexOf(REDIRECT) > -1) {
    return decodeURIComponent(href.split(REDIRECT + '=')[1]);
  }
  return isAdmin ? config.ADMIN_DOMAIN : config.MEMBERS_DOMAIN;
};

const publishLoginPageEvent = (action, eventProperties, userId) => {
  const eventType = {
    component: 'LOGIN_PAGE',
    action,
  };
  publishEvent(eventType, eventProperties, userId);
};

const publishViewPage = (eventProperties) => {
  publishLoginPageEvent('VIEW_PAGE', eventProperties);
};

const publishSubmitLoginForm = (eventProperties) => {
  publishLoginPageEvent('SUBMIT_LOGIN_FORM', eventProperties);
};

const publishReceiveLoginResult = (eventProperties, userId) => {
  publishLoginPageEvent('RECEIVE_LOGIN_RESULT', eventProperties, userId);
};
const Login = (props) => {
  const [loggingIn, setLoggingIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [gdprDeclined, setGdprDeclined] = useState(false);
  const [whiteLabel, setWhiteLabel] = useState(
    WHITE_LABEL_NAMES[transformPath(props.location.pathname)]
  );
  const [gdprModals, setGdprModals] = useState({
    consentModalIsOpen: false,
    declineModealIsOpen: false,
  });
  const [cookiesCheckmark, setCookiesCheckmark] = useState(false);
  const [consentCookies, setConsentCookies] = useState(false);
  const [applicationVersion, setApplicationVersion] = useState(null);

  const styles = getStyle(whiteLabel);

  useEffect(() => {
    if (!applicationVersion) return;
    let lastCheck = null;
    const unlisten = props.history.listen(async () => {
      const now = new Date().getTime();
      if (lastCheck) {
        const timeElapsed = now - lastCheck;
        if (timeElapsed >= 2 * 60 * 1000) {
          lastCheck = now;
          const version = `login-ui-${await fetchApplicationVersion()}`;

          if (applicationVersion === version) {
            setApplicationVersion(version);
            window.location.reload();
          }
        }
      }
      if (!lastCheck) {
        lastCheck = now;
      }
    });

    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationVersion]);

  useEffect(() => {
    const consent = localStorage.getItem('consentCookies');
    if (consent) {
      setConsentCookies(true);
      setCookiesCheckmark(true);
    }
    publishViewPage({
      redirectLink: getRedirectLink(),
      whiteLabel: whiteLabel && whiteLabel.length > 0,
    });
    fetchApplicationVersion().then((version) =>
      setApplicationVersion(`login-ui-${version}`)
    );
    document.body.classList.add(styles.background);
  }, [styles.background, whiteLabel]);

  useEffect(() => {
    if (applicationVersion) {
      updateRollbarConfig(applicationVersion);
    }
  }, [applicationVersion]);

  useEffect(() => {
    if (config.ENV_NAME === 'dev') {
      if (!whiteLabel) setWhiteLabel('');
      return;
    }
    const storedWhiteLabel = localStorage.getItem('whiteLabel');
    if (!whiteLabel) setWhiteLabel(storedWhiteLabel || '');
    if (storedWhiteLabel && props.location.pathname === '/') {
      props.history.replace(
        `/${WHITE_LABEL_NAMES[storedWhiteLabel]}${props.location.search}`
      );
    }
    if (!storedWhiteLabel && whiteLabel) {
      localStorage.setItem('whiteLabel', whiteLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname, whiteLabel]);

  useEffect(() => {
    if (loggingIn) {
      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggingIn]);

  const closeModals = () => {
    setGdprModals({
      consentModalIsOpen: false,
      declineModealIsOpen: false,
    });
  };

  const toConsentModal = () => {
    setGdprModals({
      consentModalIsOpen: true,
      declineModealIsOpen: false,
    });
  };

  const toDeclineModal = () => {
    setGdprModals({
      consentModalIsOpen: false,
      declineModealIsOpen: true,
    });
  };

  const declineGdpr = () => {
    localStorage.removeItem('consentCookies');
    closeModals();
    setGdprDeclined(true);
    setLoginError('');
    setLoggingIn(false);
    setConsentCookies(false);
    setCookiesCheckmark(false);
  };

  const acceptGdpr = () => {
    closeModals();
    saveTocConsent();
  };

  const saveTocConsent = () => {
    const consentObject = { documentReference: TOC };
    const config = {
      headers: { 'X-XSRF-TOKEN': localStorage.getItem('X-XSRF-TOKEN') },
    };
    giveConsent(consentObject, config).then((res) => {
      if (res.status === 200)
        refreshLogin(config).then((res) => {
          if (res.status === 200) login();
        });
    });
  };

  const handleChange = () => {
    setLoginError('');
    setGdprDeclined(false);
  };

  const forgot = () => {
    props.history.push('/forgot-password');
  };

  const toggleAcceptCookies = () => {
    setCookiesCheckmark(!cookiesCheckmark);
  };

  const setLoginData = (data) => {
    if (!data) return;
    publishSubmitLoginForm({
      redirectLink: getRedirectLink(),
      whiteLabel: whiteLabel && whiteLabel.length > 0,
    });

    setUserName(data.userName);
    setUserPassword(data.userPassword);
    setLoggingIn(true);
  };

  const login = async () => {
    const payload = {
      userName,
      password: userPassword,
      cookieConsentDocRef: CONSENT_COOKIE,
      whiteLabelBusiness: whiteLabel ? whiteLabel : null,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const loggedInUser = await loginUser(payload, config).catch((error) =>
      handleError(error)
    );

    if (loggedInUser?.status === 200) {
      localStorage.setItem('X-XSRF-TOKEN', loggedInUser.data.xsrfToken);
      localStorage.setItem('consentCookies', true);
      localStorage.setItem('userId', loggedInUser.data.userId);
      if (loggedInUser.data.missingConsent) {
        publishReceiveLoginResult(
          {
            resultType: 'SUCCESS',
            isMissingConsent: loggedInUser.data.missingConsent,
            redirectLink: getRedirectLink(),
            whiteLabel: whiteLabel && whiteLabel.length > 0,
          },
          loggedInUser.data.userId
        );
        missingConsent()
          .then((res) => {
            if (res.status === 200) {
              const documentReferences = res.data.documentReferences;
              promptConsentModals(documentReferences);
            }
          })
          .catch((error) => handleError(error));
      } else {
        publishReceiveLoginResult(
          {
            resultType: 'SUCCESS',
            isMissingConsent: loggedInUser.data.missingConsent,
            redirectLink: getRedirectLink(),
            whiteLabel: whiteLabel && whiteLabel.length > 0,
          },
          loggedInUser.data.userId
        );
        const isAdmin =
          loggedInUser.data.properties && loggedInUser.data.properties.isAdmin;
        setTimeout(
          () => window.location.assign(getRedirectLink(isAdmin)),
          1000
        );
      }
    }
  };

  const promptConsentModals = (documentReferences) => {
    documentReferences.map((params) => {
      if (params === TOC) {
        return toConsentModal();
      }
      return null;
    });
  };

  const handleError = (error) => {
    logErrorInfo(error);
    if (error.response && error.response.status === 429) {
      setLoginError('');
      setLoggingIn(false);
    }
    let loginError;
    if (!error.response || !error.response.status) {
      loginError = t('login.networkError');
    } else if (error.response.data && error.response.data.message) {
      loginError = error.response.data.message;
    } else if (400 <= error.response.status && error.response.status < 500) {
      loginError = t('login.clientError');
    } else if (500 <= error.response.status && error.response.status < 600) {
      loginError =
        t('login.serverError') +
        ': ' +
        error.response.status +
        ' - ' +
        error.response.statusText;
    } else {
      loginError = t('login.unexpectedError');
    }
    publishReceiveLoginResult({
      resultType: 'ERROR',
      errorMessage: loginError,
      whiteLabel: whiteLabel && whiteLabel.length > 0,
    });

    setLoginError(loginError);
    setLoggingIn(false);
  };

  const transformLocation = (inputLocation) => {
    const queryStringParsed = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const reset_token = queryStringParsed.reset_password;
    if (reset_token === undefined) {
      return inputLocation;
    }
    return {
      ...inputLocation,
      pathname: '/reset/',
    };
  };

  return (
    <div>
      <div
        className={
          loginError
            ? `${globalStyles.container} ${globalStyles.container_err}`
            : globalStyles.container
        }
      >
        <div className={styles.logo} />
        <Switch location={transformLocation(window.location)}>
          <Route
            path={`/${WHITE_LABEL_NAMES[whiteLabel]}`}
            exact
            render={() => (
              <SignInForm
                gdprDeclined={gdprDeclined}
                handleChange={handleChange}
                login={setLoginData}
                forgot={forgot}
                toggleAcceptCookies={toggleAcceptCookies}
                whiteLabel={whiteLabel}
                cookiesCheckmark={cookiesCheckmark}
                consentCookies={consentCookies}
                loginError={loginError}
                loggingIn={loggingIn}
              />
            )}
          />
          <Route
            path="/index.html"
            exact
            render={() => (
              <SignInForm
                gdprDeclined={gdprDeclined}
                handleChange={handleChange}
                login={setLoginData}
                forgot={forgot}
                toggleAcceptCookies={toggleAcceptCookies}
                whiteLabel={whiteLabel}
                cookiesCheckmark={cookiesCheckmark}
                consentCookies={consentCookies}
                loginError={loginError}
                loggingIn={loggingIn}
              />
            )}
          />
          <Route
            path="/forgot-password"
            render={() => <ForgotPasswordForm whiteLabel={whiteLabel} />}
          />
          <Route
            path="/forgot-password-confirmation"
            render={() => <EmailConfirmation whiteLabel={whiteLabel} />}
          />
          <Route path="/reset" component={ResetPasswordForm} />
          <Route path="/reset-confirmation" component={ResetConfirmation} />
          <Route path="/new-user/:token" component={NewUser} />
        </Switch>
        <Footer whiteLabel={whiteLabel} />
      </div>
      <AlertDialog
        isOpen={gdprModals.consentModalIsOpen}
        toDeclineModal={toDeclineModal}
        acceptGdpr={acceptGdpr}
        closeModals={closeModals}
      />
      <DeclineDialog
        isDeclineOpen={gdprModals.declineModealIsOpen}
        toConsentModal={toConsentModal}
        declineGdpr={declineGdpr}
        closeModals={closeModals}
      />
    </div>
  );
};

export default withRouter(Login);
